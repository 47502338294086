/* =========
  Product Variants
  This Code is Licensed by Will-Myers.com
========== */
.wm-variants {
  display:flex;
  flex-wrap: wrap;
  gap: var(--variant-gap, 8px);

  --placeholder-background: repeating-linear-gradient(
    to bottom right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 4px,
    currentColor 5px,
    currentColor 5px
  );
}
.wm-variant {
  display: grid;
  place-items: center;
  position: relative;
  height: var(--variant-size, 60px);
  min-width: var(--variant-size, 60px);
  padding: var(--variant-padding-v, 4px) var(--variant-padding-h, 8px);
  font-size: var(--variant-font-size, 14px);
  color: var(--variant-text-color, var(--paragraphMediumColor, currentColor));
  background-size: cover !important;
  background: var(--variant-background, rgba(255, 255, 255, 0));
  border-width: var(--variant-border-thickness, 1px);
  border-style: var(--variant-border-style, solid);
  border-color: var(--variant-border-color, var(--paragraphMediumColor, currentColor));
  border-radius: var(--variant-border-radius, 10px);
}
.wm-variant.active {
  background-color: var(--active-variant-background, var(--primaryButtonBackgroundColor));
  color: var(--active-variant-color, var(--primaryButtonTextColor));
  border-color: var(--active-variant-border-color, var(--variant-border-color, transparent));
}
.wm-variant.active,
.wm-variant.active:focus,
.wm-variant:focus {
  outline-width: var(--active-variant-outline-width, 2px);
  outline-offset: 2px;
  outline-color: var(--active-variant-outline-color, var(--primaryButtonBackgroundColor, currentColor));
  outline-style: solid;
}
.wm-custom-variants .variant-select-wrapper,
.wm-custom-variants .variant-radiobtn-wrapper{
  display:none !important;
}

/*Product Collection Items*/
.grid-item .wm-variants {
  justify-content: var(--variant-alignment-collection, start);
}

/*Product Details Page Items*/
.pdp-selection .wm-variants {
  justify-content: var(--variant-alignment-details, start);
}


/*Color Variants*/
[data-variant-option-name="Color"] .wm-variant span {
  display:none;
}
[data-variant-option-name="Color"] .wm-variant {
  height: var(--color-variant-size, 35px);
  width: var(--color-variant-size, 35px);
  min-width: unset;
  color: initial;
  border-radius: var(--color-variant-border-radius, 50%);
  border-width: var(--color-variant-border-thickness, 0px);
  border-color: var(--color-variant-border-color, currentColor);
  border-style: solid;
  background: var(
    --variant-background, 
    var(--placeholder-background)
  );
}
